#site-header {
  height: 135px;
}

.sign-in-page {
  .new-account,
  .return-user {
    .section-head {
      &__link {
        .button {
          color: $color--white;
        }
      }
    }
  }
  .field-container {
    &--grid {
      .form-item {
        float: none;
        clear: both;
        margin-#{$rdirection}: 0;
        padding-#{$rdirection}: 0;
      }
    }
    .mobile-phone {
      .mobile-code {
        padding-bottom: 10px;
      }
    }
  }
}

.profile-pic-upload {
  &__help {
    text-align: $ldirection;
  }
}

.profile-page {
  .profile-info {
    .form-item {
      &_birthday {
        width: 100%;
        padding-#{$rdirection}: 0;
        .select2-container {
          .select2-choice {
            height: 56px;
            line-height: 56px;
          }
        }
        .birth_day_content {
          margin-bottom: 10px;
        }
      }
      .picker {
        display: inline-block;
        margin: 0 0 1em 0;
      }
    }
    .mobile_number,
    .home_number {
      margin-top: 17px;
    }
    .home_number {
      padding-top: 18px;
    }
  }
  #profile_preferences {
    .profile-info {
      .form-item {
        &_age {
          width: 48%;
        }
      }
    }
  }
}

.address-form {
  padding: 10px;
  &__fieldset {
    .title_container {
      label {
        margin-top: 10px;
        margin-bottom: 5px;
      }
    }
    .form-item {
      width: 50%;
      float: left;
      padding-#{$ldirection}: 15px;
      margin-bottom: 15px;
      &.left {
        padding-#{$ldirection}: 0;
      }
      &.state_container {
        label {
          display: inline-block;
        }
      }
      .mobile_code {
        float: $ldirection;
        width: 45%;
        padding-#{$rdirection}: 20px;
      }
      .mobile_number {
        width: 55%;
        display: inline-block;
        input[type='text'] {
          width: 100%;
        }
      }
      .picker {
        width: 100%;
      }
    }
  }
  .main-button-wrapper {
    float: $rdirection;
    .form-submit {
      padding: 0 35px;
    }
  }
}

.order-details-page {
  .order-totals {
    table {
      margin-#{$rdirection}: 40px;
    }
  }
  .order-products {
    .cart-item {
      &__type {
        margin-#{$ldirection}: 0;
        width: auto;
      }
    }
  }
  .order-details {
    .gift-options {
      margin-#{$ldirection}: 50%;
      clear: none;
    }
    .offer-code-info {
      margin-#{$ldirection}: 25%;
      clear: none;
    }
  }
}

.newsletter-info {
  &.email_promotions {
    .picker-checkbox {
      .picker-label {
        width: 95%;
      }
    }
  }
}

.site-container {
  .account-nav {
    margin-top: 0;
  }
}

.adpl {
  .mobile_number {
    input[type='text'] {
      & + label:before {
        content: attr(alt);
      }
    }
  }
}
