.loyalty__content-bottom {
  .mac-select__marketing-page {
    .mac-select__marketing-page__header-col2 {
      .mac-select__marketing-page {
        &__signin,
        &__join__hyperlink {
          font-size: 17px;
        }
      }
    }
    .macselect-benefits__container {
      .macselect-benefits__section {
        &-levels__header-title {
          word-break: break-all;
        }
        &-levels__header-status {
          font-size: inherit;
        }
        &-fields {
          width: 50%;
        }
        &-levels {
          width: 50%;
        }
        &-title {
          width: 230px;
        }
        &-level1 {
          .macselect-benefits__section {
            &-levels__header {
              background-color: $color--light-pink;
            }
            &-copy {
              li {
                span {
                  background-color: $color--light-pink;
                }
              }
            }
          }
        }
        &-level2 {
          .macselect-benefits__section {
            &-levels__header {
              background-color: $color--red-tier2;
            }
            &-copy {
              li {
                span {
                  background-color: $color--red-tier2;
                }
              }
            }
          }
        }
        &-level3 {
          .macselect-benefits__section {
            &-levels__header {
              background-color: $color--dark-gray;
            }
            &-copy {
              li {
                span {
                  background-color: $color--dark-gray;
                }
              }
            }
          }
        }
        &-level1,
        &-level2,
        &-level3 {
          width: 33.33%;
        }
        &-copy {
          ul {
            li {
              height: 70px;
              padding: 13px 5px;
              display: flex;
              align-items: center;
              .text_content {
                background: none;
                width: auto;
                text-align: center;
              }
            }
          }
        }
      }
      .macselect-benefits__initial {
        li {
          width: 100%;
          justify-content: center;
          border: 1px solid $color--border;
          text-align: center;
        }
        &-item {
          border-top: none;
        }
      }
    }
    &-benefits-link {
      margin-top: 40%;
    }
    &__content,
    .loyalty__content-bottom__section-container {
      &-shade {
        #{$ldirection}: -308px;
        top: inherit;
        bottom: -210px;
      }
    }
    .macselect-benefits__section {
      &-terms-container__faq {
        margin-top: 5px;
      }
    }
  }
  .mac-lover__events__promos {
    padding: 3% 7%;
    margin: 0;
    width: 100%;
    display: inline-block;
    &--header {
      &-link {
        border: none;
        &:hover {
          border: none;
          text-decoration: none;
          color: $color--black;
        }
      }
    }
    &--image {
      width: auto;
    }
    &--description {
      width: auto;
    }
  }
}
