/**
 * social-login
 * This file contains all styilngs needed for facebook login implementation.
 *
 */

/* Checkout page Styling */
.checkout {
  .checkout__return-user__toggle {
    @include swap_direction(margin, 8px 0 15px 0);
  }
  &__content {
    .signin-panel,
    .sign-in-panel {
      .social-clear {
        clear: both;
      }
      .social-login {
        &__divider {
          @include swap_direction(margin, 0 auto 12px);
          @include breakpoint($bp--medium-up) {
            @include swap_direction(margin, 25px auto 25px);
          }
        }
        &__email-opt-in {
          .picker {
            .social-login {
              &__opt-in-label {
                float: none;
                text-align: $ldirection;
              }
            }
          }
        }
        &__terms {
          margin-top: 15px;
          margin-bottom: 11px;
        }
        &__title {
          display: none;
        }
        &__divider .inner {
          border: 9px solid;
          border-radius: 25px;
          padding: 4px 5px;
          background: $color--black;
          color: $color--white;
          letter-spacing: 0.02px;
          background-clip: padding-box;
        }
      }
    }
  }
}
/* Account signin page styling */
.sign-in-page {
  @include breakpoint($bp--medium-up) {
    position: relative;
    .signin-form {
      width: 50%;
      float: $ldirection;
    }
    .error_messages {
      width: 49%;
    }
    .social-login-section {
      .social-login__divider {
        display: none;
      }
    }
    .field-container-section {
      position: relative;
    }
    .divider {
      .social-login__divider {
        text-transform: uppercase;
        &:before {
          content: '';
          background: $color--black;
          width: 1px;
          top: 0;
          #{$ldirection}: 92%;
          display: inline-block;
          position: absolute;
          height: 100%;
          @include breakpoint($bp--large-up) {
            #{$ldirection}: 97%;
          }
          @include breakpoint($bp--largest-up) {
            #{$ldirection}: 100%;
          }
        }
        .inner {
          background: $color--black;
          margin-top: 0;
          top: 39%;
          #{$ldirection}: 86%;
          position: absolute;
          color: $color--white;
          padding: 4px 5px;
          border-radius: 25px;
          border: 10px solid;
          background-clip: padding-box;
          letter-spacing: 0.02px;
          @include breakpoint($bp--large-up) {
            #{$ldirection}: 92%;
          }
          @include breakpoint($bp--largest-up) {
            #{$ldirection}: 96%;
          }
          @include breakpoint($bp--xxlarge-up) {
            #{$ldirection}: 96.7%;
          }
        }
      }
    }
    .social-login-section {
      .social-terms__container {
        .social-login__terms {
          margin-#{$ldirection}: 0;
          padding-bottom: 10px;
          text-transform: uppercase;
          display: block;
          @include breakpoint($bp--larger-up) {
            margin-#{$ldirection}: 10px;
          }
          @include breakpoint($bp--xxlarge-up) {
            margin-#{$ldirection}: 55px;
          }
        }
      }
      .social-login {
        &__terms {
          display: block;
          margin-#{$ldirection}: 53px;
          text-transform: uppercase;
        }
      }
      margin-top: 0;
      vertical-align: top;
      width: 39%;
      @include breakpoint($bp--xxlarge-up) {
        width: 49%;
      }
    }
    .social-login-section,
    .divider,
    .sign-in-container {
      display: inline-block;
    }
    .sign-in-container {
      position: relative;
      width: 100%;
    }
    .field-container--grid .form-item {
      float: none;
      margin-#{$rdirection}: 0;
      padding-#{$rdirection}: 0;
      clear: both;
    }
    .social-login__divider {
      position: static;
    }
  }
  &.responsive-container {
    .social-terms__container {
      .social-login__terms {
        display: none;
        @include breakpoint($bp--medium-up) {
          display: block;
        }
      }
    }
    .divider {
      .social-login__divider {
        display: none;
        @include breakpoint($bp--medium-up) {
          display: block;
        }
      }
    }
  }
  .social-login {
    &__terms {
      margin: 20px 0;
      text-align: $ldirection;
      text-transform: uppercase;
    }
    &__email-opt-in {
      text-align: $ldirection;
      margin-top: 0;
      margin-#{$ldirection}: 10px;
      @include breakpoint($bp--larger-up) {
        margin-#{$ldirection}: 20px;
      }
      @include breakpoint($bp--xxlarge-up) {
        margin-#{$ldirection}: 65px;
      }
      .picker-label {
        float: none;
        margin-#{$ldirection}: 39px;
      }
    }
    .social-login__divider .inner {
      border: 12px solid;
      border-radius: 25px;
      padding: 4px 5px;
      background: $color--black;
      color: $color--white;
      letter-spacing: 0.02px;
    }
    &__container p {
      margin-bottom: 11px;
    }
  }
  .form-item.submit-button {
    margin-bottom: 3px;
  }
}
