@if $password_strengthen {
  .password-reset-page {
    .password-reset {
      .password-field__info-reset {
        top: 50%;
        transform: translateY(-50%);
        @include breakpoint($bp--small-down) {
          transform: translateY(0);
        }
      }
    }
  }
  .checkout-registration {
    .password-field {
      @include breakpoint($bp--medium-up) {
        width: 75%;
      }
      &__info-checkout {
        #{$ldirection}: 100%;
        margin-#{$ldirection}: 10px;
      }
    }
  }
  .profile-info {
    &__item {
      clear: left;
    }
  }
  .profile-password-update {
    &__rules {
      li {
        color: $color--gray--dark;
      }
    }
    .form-submit {
      &:disabled,
      &.disabled {
        border: 1px solid $color--gray;
      }
    }
  }
  #my-appts-registration-form {
    .registration-password-container {
      .password-field__info {
        #{$ldirection}: 82%;
        margin-#{$ldirection}: 5px;
        width: 180px;
        bottom: 30%;
      }
    }
  }
  #appointment-book-sections {
    .password-field__info {
      #{$ldirection}: 82%;
      margin-#{$ldirection}: 5px;
      width: 180px;
      bottom: 60%;
    }
  }
  .password-field {
    &__info {
      @include breakpoint($bp--medium-up) {
        width: 180px;
      }
    }
  }
}
