.loyalty__panel__offers {
  &__sku__list-container {
    .tabbed-rewards-block__tab {
      width: 32%;
      @include breakpoint($bp--large-up) {
        width: 20%;
      }
    }
  }
}

.loyalty-offer-code-panel {
  .offer-code-content__wallet {
    .offer-code-wallet__code {
      &-info--applied {
        width: 180px;
        margin: 0 auto;
        @include breakpoint($bp--xlarge-up) {
          margin: 0 25px;
          width: 125px;
        }
        @include breakpoint($bp--xxlarge-up) {
          margin: 0 30px;
          width: 180px;
        }
      }
      &__button-container {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
    }
    .slick-prev {
      position: absolute;
      top: 50%;
    }
  }
  .loyalty-offer-code {
    &-panel__title {
      display: block;
    }
    &-panel__points {
      text-align: center;
    }
  }
}

.loyalty__romance__with__rewards__details {
  .loyalty__level__3,
  .loyalty__level__2 {
    .benefits__details {
      .level__name,
      .level__text,
      .learn__more-link {
        color: $black;
        position: relative;
        top: 20px;
      }
      .benefits__details {
        width: 90%;
      }
      &__offers {
        @include breakpoint($bp--large-up) {
          width: 100%;
        }
        .icon__content {
          color: $black;
        }
      }
    }
  }
  .loyalty__level__3 {
    .benefits__details {
      bottom: 24%;
    }
  }
}

.loyalty__content-bottom,
.loyalty-select__bottom {
  .mac-select__marketing-page {
    .macselect-benefits__container {
      &__content__left {
        .macselect-benefits__container {
          &__content__level1 {
            height: 211px;
          }
          &__content__level2 {
            height: 580px;
          }
          &__content__level3 {
            height: 673px;
          }
        }
      }
      &__content__right {
        .macselect-benefits__container {
          &__content__level1 {
            height: 211px;
          }
        }
      }
    }
  }
}

.accordionPanel {
  .loyalty-offer-code-panel {
    i {
      float: #{$rdirection};
    }
    .offer-code-content {
      &__wallet {
        border-#{$ldirection}: 0;
        border-#{$rdirection}: 0;
      }
    }
  }
}

.loyalty__index__container {
  .loyalty__panel__offers {
    &__list-container,
    &__sku__list-container {
      .loyalty__panel__offer {
        &__title__details,
        &__sku__title__details {
          text-align: center;
          font-size: 16px;
          font-family: $ano-black_regular-font;
          font-weight: 700;
          text-transform: uppercase;
          span {
            border-bottom: 1px solid $color--gray--lighter;
            padding: 10px 20px;
          }
        }
      }
    }
  }
}

#loyalty__panel__offers {
  .loyalty__panel__offers {
    &__list-container {
      margin-top: 0;
      .slick-slider {
        padding: 20px;
      }
    }
  }
}

#main_content {
  .item__content {
    .icon__content {
      font-size: 11px;
    }
    margin: 0;
    padding: 0 2px 0 0;
    text-align: #{$ldirection};
  }
  .macselect-benefits {
    &__section-levels__header-status {
      font-size: 13px;
    }
  }
  .macselect-benefits__section-copy {
    .content__next {
      font-size: 13px;
    }
  }
}

.mac-select__account-panel {
  &__header-left {
    float: #{$ldirection};
  }
  &__header-right {
    display: flex;
    flex-direction: column;
    position: absolute;
    #{$rdirection}: 50px;
  }
  &__content-spend,
  &__content-processing,
  &__content-details {
    clear: both;
  }
  &__content {
    position: relative;
  }
}

.loyalty__content-bottom {
  &__section-container-content {
    .macselect-benefits {
      &__container_additional_info {
        .marketing-content {
          color: $white;
          text-align: center;
          padding: 10px;
        }
      }
    }
  }
}

// logged in
.elc-user-state-logged-in {
  .mac-select__marketing-page__join__hyperlink {
    display: block;
  }
  .mac-select__marketing_page__signup {
    display: none;
  }
}

// logged out
.elc-user-state-anonymous {
  .mac-select__marketing-page__join__hyperlink {
    display: none !important;
  }
  .mac-select__marketing_page__signup {
    display: block;
  }
}

.mac-select__marketing_page__signup {
  text-align: #{$ldirection};
  margin-top: 20px;
  a {
    width: 180px;
    display: inline-block;
    background: $white;
    color: $black;
    font-family: $ano-black_regular-font;
    font-size: 16px;
    text-align: center;
    height: 45px;
    line-height: 2.8;
  }
}

a.loyalty__panel__offers__sku__loader__button {
  &.loyalty__panel__product__button {
    &.button {
      &.btn-shopnow {
        color: $white;
      }
    }
  }
  &.loyalty__panel__offers__sku__remove {
    &.button--offers {
      &.button {
        color: $white;
      }
    }
  }
}

.loyalty__panel__offers__sku__loader__button {
  &.loyalty__panel__product__button {
    &.button--freeze {
      pointer-events: none;
      cursor: none;
      background: $color--gray--lighter;
    }
  }
}

#confirmation-page {
  .confirmation-panel__email-coming {
    &.loyalty__points-earned {
      color: $black;
    }
  }
}

#loyalty__panel__points {
  .points__details__section-right {
    .rewards {
      .loyalty__points {
        font-size: 45px;
      }
    }
  }
}

.checkout__content {
  .product-brief-carousel {
    &.grid--mpp__carousel {
      .loyalty__panel__offers__offer-image {
        margin: 0 auto;
        padding: 0 10px;
      }
      .slick-next,
      .slick-prev {
        &.slick-disabled {
          opacity: 0.2;
        }
        &::before {
          display: none;
        }
      }
      .slick-next {
        background: url('/media/export/cms/loyalty/pc-rewards-right-arrow.png') no-repeat;
      }
      .slick-prev {
        background: url('/media/export/cms/loyalty/pc-rewards-left-arrow.png') no-repeat;
        position: absolute;
        top: 50%;
      }
      .loyalty__panel {
        &__offers__offer-header {
          font-size: 16px;
          margin-top: 20px;
        }
      }
    }
  }
}
