.appt-book {
  .appt-book-page-header-content__banner {
    margin-top: 0px !important;
  }
  .my-appointments {
    .appointments-container.past {
      .appointment-details {
        .date-time {
          .appt-date-day {
            width: auto;
          }
        }
      }
    }
  }
}
