@mixin samples_overlay {
  .samples-panel {
    padding-bottom: 2em;
    @include breakpoint($width-large) {
      padding-bottom: 1em;
    }
    &__title {
      text-align: center;
    }
    .samples {
      text-align: center;
      .product {
        width: 33%;
        padding: 0 5px;
        float: $ldirection;
        text-align: center;
        margin-bottom: 30px;
        min-height: 450px;
        .product-img {
          margin-bottom: 10px;
        }
        .product-name {
          font-size: 1rem;
          font-family: $roboto-mono_regular-font;
          line-height: 18px;
        }
        .skus {
          position: absolute;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          bottom: 0;
        }
        .sample-select-button {
          height: 40px;
          line-height: 40px;
          padding: 0 25px;
        }
      }
    }
    .sample_continue {
      @include breakpoint($width-xlarge) {
        display: block;
        margin: 10px 5px;
        text-align: center;
        clear: $ldirection;
        input[type='submit'] {
          width: 25%;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    &__continue-checkout {
      &--button {
        @include breakpoint($width-medium) {
          width: 100%;
          padding: 1em;
        }
        @include breakpoint($width-large) {
          width: 228px;
        }
      }
    }
  }
}

#bottom-viewcart-buttons {
  .continue-button-wrapper {
    position: fixed;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    height: 7em;
    z-index: 999;
    border-top: 1px solid $color--black;
    background-color: $color--white;
    padding: 1em;
    .btn {
      width: 100%;
    }
    .continue-shopping {
      display: none;
    }
  }
  @include breakpoint($width-medium) {
    .viewcart-buttons-panel {
      background-color: $color--white;
    }
    .continue-buttons {
      width: 40%;
      float: $rdirection;
      position: relative;
      border: none;
      .continue-checkout,
      .go-shopping {
        width: 100%;
      }
      @include breakpoint($width-xlarge) {
        padding-#{$rdirection}: 5em;
      }
    }
    .continue-shopping {
      margin: 2em;
      display: inline-block;
    }
  }
}

.checkout {
  margin: 0 auto;
  width: 100%;
  .site-container {
    max-width: 1281px;
    margin: 0 auto;
    width: 100%;
  }
  &__content {
    padding: 0 1em 1em;
  }
  &-page-title {
    @include breakpoint($width-medium) {
      font-size: 30px;
      padding: 20px 0;
    }
  }
  .cart-header {
    &__title {
      &--products {
        margin-#{$ldirection}: 1em;
      }
      &--total {
        width: 9.33%;
      }
    }
  }
  .cart-item {
    &__price {
      font-size: 1.5rem;
      font-family: $ano-bold_regular-font;
      margin-top: 1em;
      &--label {
        display: none;
      }
    }
    &__qty {
      &--label {
        display: none;
      }
    }
  }
  .recommended-products-panel {
    .info {
      .formatted_price {
        position: absolute;
        bottom: 60px;
      }
    }
    .addtobag {
      position: absolute;
      bottom: 0;
    }
  }
  #offer-code-panel {
    &.zero-offer {
      display: none;
    }
    .offer-code {
      &__input {
        input {
          background-color: $color--white;
        }
      }
      &-panel {
        &__title {
          width: 100%;
          padding: 0 1em;
          @include breakpoint($width-xlarge) {
            padding: 0;
          }
        }
      }
    }
  }
  .tab-switcher {
    @include breakpoint($width-medium) {
      display: none;
    }
    @include breakpoint($width-xlarge) {
      display: block;
    }
    margin-top: 2em;
    .tab-bar {
      padding: 1.2em;
      background-color: $color--gray--white;
      .tab {
        width: 48%;
        display: inline-block;
        font-size: 22px;
        font-family: $ano-bold_regular-font;
        cursor: pointer;
        color: $color--gray--lighter;
        text-transform: uppercase;
        &.active {
          color: $color--black;
        }
      }
    }
    .tab-pane {
      display: none;
      &.active {
        display: block;
      }
      header {
        display: none;
      }
      &.samples-pane,
      .past-purchases-panel {
        .product-img {
          width: 35%;
          float: $ldirection;
          margin-#{$rdirection}: 3%;
        }
        .product-name {
          font-size: 1rem;
          font-family: $roboto-mono_regular-font;
        }
        .sample-select-button {
          height: 2em;
          line-height: 2em;
        }
        .add-button {
          display: none;
        }
      }
      #samples-panel,
      #past-purchases-panel {
        .product-list {
          overflow: hidden;
          max-height: none;
        }
        .btn {
          margin-top: 10px;
        }
      }
      .past-purchases-panel {
        .details {
          width: 60%;
          float: $ldirection;
          .add-to-cart {
            .btn {
              width: 65%;
              height: 40px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }
  .links-panel {
    margin-top: 2em;
  }
  .mobile-breadcrumb {
    display: none;
  }
  .checkout-index-header {
    text-align: center;
  }
  .new-account {
    &__new-customers-checkout-signin-text {
      display: none;
    }
    &__submit {
      width: 100%;
    }
  }
  .return-user {
    &__submit {
      width: 100%;
    }
  }
  &__sidebar {
    padding-bottom: 7em;
    @include breakpoint($width-large) {
      padding: 0 1em 1em 0;
    }
    .viewcart-panel {
      &__title {
        width: auto;
        display: inline;
        .edit {
          border: none;
        }
      }
      &__edit {
        width: auto;
        float: $rdirection;
        padding: 1em 0;
        .edit {
          border-bottom: 1px solid $color--black;
        }
      }
      .cart-header {
        display: none;
      }
      .cart-item {
        &__thumb {
          width: 40%;
          float: $ldirection;
        }
        &__desc {
          width: 55%;
          float: $ldirection;
          margin-#{$ldirection}: 5%;
          .cart-item {
            &__price {
              display: none;
            }
          }
        }
        &__qty {
          margin: 2% 0 0 5%;
          width: 55%;
          float: $rdirection;
          &--label {
            display: inline-block;
          }
        }
        &__product-name {
          margin-bottom: 0.2em;
        }
        &__price {
          font-size: inherit;
          font-family: inherit;
          margin-top: 0;
          &.mobile_hidden {
            display: none;
          }
        }
        &__size {
          letter-spacing: -1px;
          margin-top: 1em;
        }
        &__total {
          margin-top: 1.5em;
        }
      }
    }
    .order-summary {
      &__content {
        .label,
        .value {
          &.total {
            background: initial;
            padding: 0;
          }
        }
      }
    }
  }
  .sign-in-panel {
    padding: 1em 0;
  }
  .shipping-panel {
    .delivery-to-use {
      .picker {
        display: inline-block;
      }
    }
    .address-form {
      max-width: 660px;
      padding: 0;
      .name-fields {
        .form-item {
          width: 49%;
          float: $ldirection;
          &.first-name {
            margin-#{$rdirection}: 2%;
            clear: both;
          }
        }
      }
      .city-state-zip {
        .form-item {
          width: 49%;
          float: $ldirection;
          padding: 0;
          &.state {
            margin-#{$rdirection}: 2%;
          }
        }
      }
      .phones {
        .form-item {
          width: 100%;
          .area-code {
            margin-#{$rdirection}: 2%;
            width: 49%;
            float: $ldirection;
          }
          .mobile-number {
            width: 48%;
            float: $ldirection;
          }
        }
      }
    }
    .valid_marker,
    .invalid_marker {
      display: none;
    }
    .shipping-address-display,
    .gift-options-display,
    .billing-address-display {
      width: 50%;
      float: $ldirection;
    }
    .billing-address-display {
      clear: both;
    }
    #continue-btn {
      padding: 1em 0;
    }
  }
  .gift-options {
    &__content {
      width: 70%;
    }
    .card-message-to {
      width: 49%;
      float: $ldirection;
    }
    .card-message-from {
      width: 49%;
      float: $rdirection;
    }
    .card-message {
      textarea {
        background-color: $color--white;
      }
    }
  }
  .product_name {
    font-size: 1rem;
    line-height: 18px;
    font-family: $roboto-mono_regular-font;
    .sub_line {
      margin-bottom: 10px;
    }
  }
  .product-info {
    &__subline {
      a {
        border: none;
        font-weight: bold;
      }
    }
  }
  .samples-page {
    @include breakpoint($width-medium) {
      @include samples_overlay;
      .sample_continue {
        clear: both;
        text-align: center;
        input[type='submit'] {
          width: 25%;
        }
      }
    }
    .sample_continue {
      @include breakpoint($width-medium) {
        display: none;
      }
    }
  }
}

.viewcart-empty {
  #offer-code-panel {
    padding: 0;
  }
  .item-count {
    display: none;
  }
}

.viewcart {
  &.checkout {
    .checkout {
      &__sidebar {
        padding-bottom: 1em;
      }
    }
  }
  .checkout__content {
    .order-summary {
      &__subtotal-label {
        width: auto;
      }
      &__content {
        .label {
          margin-#{$ldirection}: 16.66667%;
          &.total {
            margin-#{$ldirection}: 16.66667%;
          }
        }
        .value {
          width: 11.33%;
          margin-#{$ldirection}: 80.33%;
        }
      }
    }
  }
  .site-footer {
    @include breakpoint($width-large) {
      display: block;
    }
  }
}

.language-toggle-node {
  @include breakpoint($width-medium) {
    text-align: center;
    padding-top: 2em;
    clear: both;
  }
  @include breakpoint($width-large) {
    display: none;
  }
}

.samples-overlay {
  @include samples_overlay;
  @include breakpoint($width-medium) {
    @include samples_overlay;
    .sample_continue {
      clear: both;
      text-align: center;
      input[type='submit'] {
        width: 25%;
      }
    }
    .language-toggle-node {
      clear: both;
      text-align: center;
    }
  }
}

.review-panel {
  .picker {
    .picker-handle {
      margin-top: 10px;
    }
    .picker-label {
      .payment_options_image {
        margin-#{$ldirection}: 10px;
      }
    }
  }
  .divide {
    .picker-handle {
      margin-top: 4px;
    }
    .label-content {
      vertical-align: middle;
    }
  }
  .review-submit {
    @include breakpoint($width-medium) {
      padding: 1em;
    }
  }
}

#confirmation-page {
  .need-help-panel {
    .text {
      font-size: 12px;
      font-family: $roboto-mono_regular-font;
    }
  }
  .confirmation-panel {
    border-bottom: none;
    &__content {
      border-bottom: none;
    }
    &__email-coming {
      color: $color--black;
    }
    &__actions {
      padding-top: 0;
    }
  }
  #registration-panel {
    margin-#{$ldirection}: 0;
    header {
      border-bottom: 1px solid $color--gray--lightest;
    }
    .registration-panel {
      &__title {
        font-size: 30px;
        padding-bottom: 10px;
      }
    }
  }
  .social-login {
    &__terms {
      display: block;
      text-transform: uppercase;
      padding: 0;
    }
    &__opt-in-label {
      display: inline-block;
      width: 85%;
    }
  }
}

.confirm {
  .site-header {
    .site-navigation {
      .main-menu {
        display: none;
      }
      .checkout-navigation__menu {
        display: block;
      }
    }
    &__extra {
      display: none;
    }
    &__tools {
      .site-my-mac,
      .site-email-signup {
        display: none;
      }
    }
  }
}

.checkout-registration {
  .mobile-phone {
    .mobile-code {
      display: inline-block;
      width: 348px;
      select {
        height: 60px;
      }
    }
    .mobile-number {
      display: inline-block;
      width: 348px;
    }
  }
}
