.site-footer {
  &--top {
    .language {
      .switch-lang-link-hk {
        @include breakpoint($bp--large-up) {
          color: $color--gray--light;
        }
      }
    }
    .trustmark-logo {
      text-align: center;
      margin-top: 1em;
    }
  }
  &--sticky {
    .trustmark-logo {
      display: none;
      @include breakpoint($width-large) {
        display: block;
      }
    }
  }
}

.profile-page {
  .form-item {
    margin-bottom: 15px;
    .label-content {
      text-transform: uppercase;
    }
    &_birthday {
      .form-item_month_year {
        .error {
          border: 1px solid $color--red;
        }
      }
    }
  }
  .label {
    text-transform: uppercase;
  }
  .profile_preferences_optout {
    ~ .picker-checkbox {
      label {
        width: 90%;
        text-align: $ldirection;
      }
    }
  }
  &__content {
    margin-top: 20px;
  }
}

.address-form {
  &__fieldset {
    .title_container {
      margin-bottom: 15px;
    }
    .form-item {
      &.wide {
        clear: $ldirection;
        input[type='checkbox'] {
          vertical-align: middle;
        }
        label {
          display: inline-block;
        }
      }
      .mobile_code {
        select {
          height: 60px;
          line-height: 60px;
        }
      }
      &.first_name_container,
      &.last_name_container,
      &.address1_container,
      .mobile_number {
        .error {
          border: 1px solid $color--red;
        }
      }
    }
  }
}

.customer-service {
  &__page-content {
    .sub-heading {
      font-size: 15px;
      font-weight: 500;
    }
  }
  &__navigation {
    .customer-service {
      &__menu {
        width: 100%;
        margin-#{$ldirection}: 0;
        margin-#{$rdirection}: 0;
        .orders,
        .exchange,
        .shipping,
        .shopping,
        .faq {
          width: 18%;
          margin-#{$ldirection}: 20px;
          margin-#{$rdirection}: 0;
        }
        .shopping {
          float: $ldirection;
        }
        .faq {
          clear: none;
        }
      }
    }
  }
}

.search-results {
  .tout-block-landscape {
    @include breakpoint($width-medium) {
      &__caption {
        #{$ldirection}: 0;
        padding-top: 5px;
      }
      &__headline {
        line-height: 24px;
      }
      &__body {
        padding-top: 5px;
      }
      &__cta {
        height: 45px;
        line-height: 3em;
      }
    }
  }
}

.social-login {
  &.gnav {
    .picker-label {
      width: 85%;
      text-align: $ldirection;
    }
  }
}

.site-header {
  .site-header {
    &__tools {
      .site-my-mac {
        &__link {
          font-size: 12px;
        }
        &__account {
          display: block;
          font-family: $roboto-mono_regular-font;
          font-size: 12px;
          height: 60px;
          line-height: normal;
          padding: 18px 0 0 0;
          text-align: left;
          text-transform: uppercase;
          white-space: nowrap;
          br {
            display: none;
          }
        }
      }
    }
    &__fixed-wrapper {
      height: 120px;
      top: auto;
    }
  }
  .site-navigation {
    .checkout-navigation {
      &__menu {
        display: none;
      }
    }
  }
}

.viewcart,
.checkout {
  .site-header {
    .site-header {
      &__tools {
        .site-my-mac {
          &__link {
            @include breakpoint($width-large) {
              display: block;
            }
          }
        }
      }
    }
  }
}

.product {
  &--full {
    .product {
      &__subline {
        font-family: $roboto-mono_regular-font;
      }
      &__footer {
        .product {
          &__price {
            @include breakpoint($width-xlarge) {
              float: $ldirection;
            }
          }
        }
      }
    }
  }
  &--teaser {
    .sold-out {
      width: 100%;
      @include breakpoint($bp--small-up) {
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        width: auto;
      }
    }
  }
  &__header {
    .product_header_details {
      .product {
        &__short_desc {
          font-size: 1rem;
          width: 90%;
        }
      }
    }
  }
}

.site-container {
  position: relative;
  bottom: 12px;
  .responsive-container {
    .signup-thanks {
      &__header {
        padding-top: 0;
        margin-top: 150px;
      }
    }
  }
  .grid {
    &--mpp {
      &__item {
        .prod_inv_status-5 {
          .inactive {
            display: block;
            padding: 0;
            font-size: 12px;
          }
        }
        .prod_inv_status-3 {
          .product {
            &__inventory-status {
              .coming-soon {
                display: block;
              }
            }
          }
        }
        .product {
          &--teaser {
            .product {
              &__footer {
                .product {
                  &__inventory-status {
                    @include breakpoint($bp--large-up) {
                      float: $rdirection;
                      padding: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .collection-detail-formatter {
    .prod_inv_status-2,
    .prod_inv_status-3 {
      .product {
        &__footer {
          a {
            &.notify_me {
              line-height: 30px;
              top: 0;
            }
          }
        }
      }
    }
  }
  .order-details-page {
    .cart-item {
      .coming-soon {
        margin-#{$ldirection}: 30px;
      }
    }
  }
  .past-purchases {
    .product {
      &__footer {
        .product-item {
          &__out-of-stock {
            @include breakpoint($bp--large-up) {
              top: 20px;
            }
            top: 0;
          }
        }
      }
    }
  }
  .artist {
    &--products {
      .prod_inv_status-2 {
        .product {
          &__inventory-status {
            .temp-out-of-stock {
              display: block;
            }
          }
        }
      }
      .prod_inv_status-3 {
        .product {
          &__inventory-status {
            .coming-soon {
              display: block;
            }
          }
        }
      }
      .prod_inv_status-5 {
        .product {
          &__inventory-status {
            .inactive {
              display: block;
              padding-top: 8px;
            }
          }
        }
      }
    }
  }
  .page--spp {
    &__product {
      .product {
        &__footer {
          .product {
            &__inventory-status {
              margin-bottom: 0;
              .notify-status {
                .notify_me {
                  @include breakpoint($bp--large-up) {
                    font-size: 1.5em;
                    height: 60px;
                    line-height: 64px;
                    padding: 0;
                    position: relative;
                    width: 100%;
                  }
                }
              }
              .coming-soon_text,
              .temp-out-of-stock__text {
                @include breakpoint($bp--large-up) {
                  position: relative;
                }
              }
            }
            &__social-links {
              width: 40px;
            }
          }
        }
        &--not-shoppable {
          .product {
            &__sticky-footer {
              .product {
                &__inventory-status {
                  .temp-out-of-stock {
                    &__text {
                      bottom: 0;
                    }
                  }
                  .coming-soon {
                    .notify_me {
                      position: static;
                    }
                  }
                  .notify-status {
                    margin-top: 20px;
                  }
                }
                &__price {
                  margin-top: 0;
                  margin-bottom: 60px;
                }
                &__name {
                  margin-#{$rdirection}: 0;
                  width: 58%;
                }
              }
              .notify-status {
                .notify_me {
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
  .product {
    &__reviews-spp {
      display: none;
    }
    &__social-links {
      li {
        float: none;
        display: inline-block;
        margin: 0 3px;
      }
    }
  }
  .contact-us-page {
    .contact-nav {
      padding-bottom: 8px;
    }
  }
  .artistry {
    &--artist {
      .shades--12 {
        .shade-box {
          cursor: pointer;
        }
      }
    }
  }
}

.waitlist-iframe-wrapper {
  width: 95%;
  min-height: 220px;
}

.tiny-waitlist-overlay {
  .waitlist_header,
  .waitlist_thankyou_heading {
    font-size: 26px;
  }
  .waitlist_thankyou_message {
    padding-top: 10px;
  }
  .email_input {
    float: $ldirection;
    @include breakpoint($bp--large-up) {
      width: 70%;
      margin-#{$rdirection}: 20px;
    }
    width: 70%;
    margin-#{$rdirection}: 3px;
  }
  .submit_input {
    float: $ldirection;
    width: 24%;
  }
  .terms_condition_cont {
    clear: $ldirection;
    padding-top: 10px;
  }
}

.section-stores {
  .store-locator-landing {
    &__form {
      .form-type-textfield {
        background-color: $color--white;
      }
    }
  }
  .store-locator-results {
    .form {
      &--search--advanced {
        .form {
          &--search {
            @include breakpoint($bp--largest-up) {
              width: 70%;
            }
          }
        }
      }
    }
  }
}

.checkout {
  &__content {
    .registration-panel,
    .review-panel {
      &__privacy-policy {
        .picker {
          display: inline;
          margin-#{$rdirection}: 0;
          &-handle {
            margin-top: 0;
          }
          .label-content {
            display: none;
          }
        }
        .terms-text {
          display: inline;
          text-transform: uppercase;
          color: $color--gray--light;
        }
      }
    }
  }
}

.checkout-confirmation-page {
  .checkout {
    &__content {
      .registration-panel {
        &__privacy-policy {
          .terms-text {
            display: none;
          }
        }
      }
    }
  }
}

.sign-in-page {
  .signin-form {
    padding-top: 10px;
  }
  @include breakpoint($bp--large-down) {
    margin-top: 70px;
  }
}

.password-request {
  .error_messages {
    margin-bottom: 1em;
  }
}

.mpp-sort {
  &__container {
    &.select {
      &--plain {
        margin-top: $site-header-height;
        @include breakpoint($bp--medium-up) {
          margin-top: 20px;
        }
      }
    }
  }
}

[lang='en-e-HK'],
.en_HK {
  .lang-en {
    border-bottom: none;
  }
}

[lang='zh-hant-e-HK'],
.zh_HK {
  .lang-zh {
    border-bottom: none;
  }
}

.section-home,
.section-powder-kiss-lipstick {
  .product-brief {
    &__footer {
      margin: 0;
      .product-brief {
        &__product-name {
          float: none;
        }
      }
    }
  }
}

input,
select,
textarea {
  &.error {
    border: 1px solid $color--red !important;
  }
}

.form-item {
  .bgdisable {
    background-color: $color--gray--light;
    pointer-events: none;
  }
}

.social-info {
  max-width: 1281px;
  margin: 0 auto;
}

.account-nav {
  margin-top: 72px;
}

.filterable-products {
  &__result-shade-type {
    span {
      transform: rotate(360deg);
    }
  }
}

.product-foundation-vto__btn {
  width: 235px;
}

.section-products {
  @include breakpoint($bp--large-down) {
    #main_content {
      top: 70px;
      margin-bottom: 70px;
    }
  }
  .sec-nav {
    &.site-banner {
      &__sticky-fix {
        position: relative;
        top: 30px;
      }
    }
  }
  .grid {
    &--mpp {
      margin-top: 30px;
    }
  }
  &.has-sort-grid-menu {
    &.has-subnav {
      .field-elc-mpp-content {
        .sec-nav {
          &.site-banner {
            &__sticky-fix {
              position: relative;
              top: 30px;
            }
          }
        }
        .mpp-sort {
          &__container {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

.page-node {
  .site-container {
    position: relative;
    bottom: 24px;
    margin-bottom: 70px;
  }
  &.not-front {
    &.section-collections-holiday {
      .site-container {
        top: auto;
      }
    }
  }
  .site-banner {
    &__sticky-fix {
      margin-top: 45px;
    }
  }
  &.has-subnav {
    &.section-bestsellers {
      .site-banner {
        &__sticky-fix {
          top: 70px;
        }
      }
      &.toolbar {
        .site-banner {
          &__sticky-fix {
            top: 170px;
          }
        }
      }
      .field-content {
        .mpp-custom-override-layout {
          margin-top: 75px;
          @include breakpoint($bp--large-up) {
            margin-top: 60px;
          }
        }
      }
    }
  }
}

.section-product {
  #main_content {
    top: 90px;
    margin-bottom: 70px;
  }
  .spp-breadcrumbs {
    margin-top: 80px;
  }
  @include breakpoint($bp--large-down) {
    #main_content {
      top: 70px;
      margin-bottom: 60px;
    }
  }
}

.section-essentials-makeup {
  .field-content {
    margin-top: 85px;
  }
}

.viewcart {
  .site-container {
    .responsive-container {
      margin-top: 80px;
    }
  }
}

.product-foundation-vto {
  &__all-shades {
    margin-top: 0;
  }
}

.content-block-formatter {
  .slide-wrapper {
    .content-block--grid-item {
      &.comparison-item {
        background-color: $color--white;
      }
    }
  }
}

.trending-looks-block__content-article {
  .trending-looks-item-article-trigger {
    @include breakpoint($width-large) {
      &__title {
        text-orientation: sideways;
      }
    }
  }
}
